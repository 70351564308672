import { HeaderNavbar, MainFooter } from "@allsynx/components";
import { Outlet } from "react-router-dom";
import { useContext, useState } from "react";
import { CompanyInfo } from "types/company-info";
import { fetchGraphQlAnonymous } from "utils/api-util";
import { localStorageUtil } from "utils/local-storage-util";
import { NavigationContext } from "contexts/navigation-context";
import { APP_SERVER_NAME } from "utils/environment-constants";
import { urls } from "utils/environment-constants";

function Frame() {
  const { footerHasSideBar } = useContext(NavigationContext);
  const [companyLogoSource, setCompanyLogoSource] = useState<string | undefined>(undefined);
  const [isCompanyLogoSourceLoading, setIsCompanyLogoSourceLoading] = useState(true)

  const setWebDirForLogo = (webDirectory: string) => {
    // if passed as empty or null, check local storage
    if (!webDirectory) {
      const localWebDir = localStorageUtil.getItem<string>("webDir");

      if (localWebDir) {
        setWebDirForLogo(localWebDir);
      } else {
        setCompanyLogoSource(undefined);
        setIsCompanyLogoSourceLoading(false);
      }
    } else {
      if (webDirectory.toLowerCase() === "admin") {
        setCompanyLogoSource(undefined);
        setIsCompanyLogoSourceLoading(false);
      } else {
        fetchGraphQlAnonymous<CompanyInfo>(
          `query {
            companyInfo(webDirectory:"${webDirectory}") {
              companyId
              }
              }`,
              "companyInfo"
            ).then((response) => {
              if (response?.resObj?.companyId) {
                setCompanyLogoSource(
                  `${urls.companiesApiBaseUrl}/api/companylogo/${response.resObj.companyId}`
                );
              }
              setIsCompanyLogoSourceLoading(false);
        });
      }
    }
  };

  return (
    <>
      <HeaderNavbar centerElement={!isCompanyLogoSourceLoading && <HeaderNavbar.Logo companyLogoSrc={companyLogoSource}/>} showImageOnSmallWidth={true} />

      {/* <Outlet context={setImageSource} /> */}
      <Outlet context={setWebDirForLogo} />

      <MainFooter hasSidebar={footerHasSideBar} hostname={APP_SERVER_NAME} />
    </>
  );
}

export default Frame;
